import { Theme } from '@mui/material'
import { Components, alpha } from '@mui/material'
import { cyan } from '@mui/material/colors'
import { Colors } from '../colors'
import { customColors } from '../customColors'

const lightComponentsOverrides: Components<Omit<Theme, 'components'>> = {
  MuiLink: {
    styleOverrides: {
      root: {
        color: cyan['700'],
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: Colors.grey['t98'],
        // border: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
  // MuiTypography: {
  //   styleOverrides: {
  //     colorTextPrimary: {
  //       color: Colors.grey['100'],
  //     },
  //     colorTextSecondary: {
  //       color: Colors.grey['t50'],
  //     },
  //   },
  // },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#606060',
        },
      },
      input: {
        '&::placeholder': {
          color: customColors.EerieBlack,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      // Give tooltips a light background so that syntax highlighting looks good
      tooltip: {
        color: Colors.grey['100'],
        backgroundColor: alpha(Colors.grey['t92'], 0.95),
      },
    },
  },
}

export default lightComponentsOverrides
