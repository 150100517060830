import { ChangeEvent, KeyboardEvent, Ref, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { FormHelperText, OutlinedInput, darken, lighten } from '@mui/material'
import { Theme } from '@mui/material'
import { Adornment } from './Adornment'
import { CommonControlDefaultProps, CommonControlProps } from './BaseControl'
import { InputLabel } from './InputLabel'

export const inputBlurStyles = (theme: Theme) => ({
  transition: 'border-color 0.2s',
  borderColor: theme.palette.borderColor.main,
})

export const inputHoverStyles = (theme: Theme) => ({
  borderColor: (theme.palette.mode === 'dark' ? lighten : darken)(
    theme.palette.borderColor.main,
    0.5,
  ),
})

export const inputFocusedStyles = (theme: Theme) => ({
  boxShadow: `0px 0px 0.3125rem ${theme.palette.primary.main}`,
  borderColor: theme.palette.primary.main,
  borderWidth: 2,
})

const useStyles = makeStyles<{ flexWrap: any; noInputPadding?: boolean }, 'marginDense'>()(
  (theme: Theme, { flexWrap, noInputPadding }, classes) => {
    const rootHoverNotchedOutline = inputHoverStyles(theme)
    const rootHFocusedNotchedOutline = inputFocusedStyles(theme)
    const rootDisabledNotchedOutline = inputBlurStyles(theme)

    return {
      root: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.inputBackground,
        '&:hover $notchedOutline': rootHoverNotchedOutline,
        '&.Mui-focused $notchedOutline': rootHFocusedNotchedOutline,
        '&.Mui-disabled $notchedOutline': rootDisabledNotchedOutline,
        // @ts-ignore
        flexWrap: flexWrap ? 'wrap' : 'nowrap',
        '&:hover input:not(:focus) + fieldset': {
          borderColor: `${(theme.palette.mode === 'dark' ? lighten : darken)(
            theme.palette.borderColor.main,
            0.25,
          )} !important`,
        },
      },
      notchedOutline: inputBlurStyles(theme),
      adornedStart: {
        paddingLeft: theme.spacing(1),
      },
      adornedEnd: {
        paddingRight: theme.spacing(1),
      },
      disabled: {
        backgroundColor:
          theme.palette.mode === 'dark' ? '#3A444D' : theme.palette.action.disabledBackground,
      },
      input: {
        paddingTop: noInputPadding ? 0 : 9,
        paddingBottom: noInputPadding ? 0 : 9,
        'textarea&': {
          paddingTop: 0,
          paddingBottom: 0,
        },
        [`.${classes.marginDense} &`]: {
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
        },
        '&::placeholder': {
          fontWeight: 500,
          opacity: 0.4,
        },
        '&:-webkit-autofill': {
          '&, &:hover': {
            '-webkit-box-shadow': `0 0 0px 1000px ${
              theme.palette.mode === 'dark'
                ? theme.palette.common.black
                : theme.palette.common.white
            } inset !important`,
          },
        },
      },
      focused: {},
      marginDense: {},
    }
  },
)

export interface CommonInputProps extends CommonControlProps {
  /**
   * This prop helps users to fill forms faster, especially on mobile devices. You can learn more about it
   * <a href="https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill" target="_blank">following the specification</a>.
   */
  autoComplete?: string
  /** If `true`, the `input` element will be focused during the first mount. */
  autoFocus?: boolean
  /** End `InputAdornment` for this component. */
  endAdornment?: JSX.Element
  /** If `true`, input will be large. */
  large?: boolean
  /** Name attribute of the `input` element. */
  name?: string
  /** The short hint displayed in the input before the user enters a value. */
  placeholder?: string
  /** Start `InputAdornment` for this component. */
  startAdornment?: JSX.Element
}

export const CommonInputDefaultProps = {
  ...CommonControlDefaultProps,
  autoFocus: false,
  large: false,
}

interface Props extends CommonInputProps {
  inputProps?: object
  InputProps?: object
  inputRef?: Ref<HTMLInputElement>
  labelRequired?: boolean
  max?: number
  min?: number
  maxRows?: number
  minRows?: number
  multiline?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: KeyboardEvent) => void
  type?: string
  value?: string | number
  flexWrap?: boolean
  qaData?: string
  noInputPadding?: boolean
}

export function BaseInput({
  style,
  autoComplete,
  autoFocus,
  disabled,
  endAdornment,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  inputProps,
  InputProps,
  inputRef,
  label,
  labelRequired,
  large,
  max,
  min,
  maxRows,
  minRows,
  multiline,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  required,
  startAdornment,
  type,
  value,
  flexWrap,
  qaData,
  noInputPadding,
}: Props) {
  const { classes } = useStyles({ flexWrap, noInputPadding })
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div>
      {label && (
        <InputLabel id={name} required={required || labelRequired}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        style={style}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        classes={classes}
        disabled={disabled}
        endAdornment={
          endAdornment && (
            <Adornment focused={isFocused} position="end">
              {endAdornment}
            </Adornment>
          )
        }
        error={error}
        fullWidth={fullWidth}
        id={name}
        inputProps={{
          max,
          min,
          'data-qa': qaData,
          ...inputProps,
        }}
        inputRef={inputRef}
        margin={large ? 'none' : 'dense'}
        maxRows={maxRows}
        minRows={minRows}
        multiline={multiline}
        notched={false}
        name={name}
        onBlur={(event) => {
          setIsFocused(false)
          onBlur && onBlur(event)
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={(event) => {
          setIsFocused(true)
          onFocus && onFocus(event)
        }}
        placeholder={placeholder}
        required={required}
        startAdornment={
          startAdornment && (
            <Adornment focused={isFocused} position="start">
              {startAdornment}
            </Adornment>
          )
        }
        type={type}
        value={value}
        {...InputProps}
      />
      {(helperText || gutterBottom) && (
        <FormHelperText error={error}>{helperText || (gutterBottom && ' ')}</FormHelperText>
      )}
    </div>
  )
}

BaseInput.defaultProps = CommonInputDefaultProps
