import { useRouter } from 'next/router'
import { useGetEnvironment } from '../environment/environmentQueries'

export const useCurrentEnvironment = () => {
  const {
    query: { environmentName, organizationSlug, propertySlug },
  } = useRouter()
  const {
    environment: currentEnvironment,
    currentActorEnvironmentRole,
    loading,
  } = useGetEnvironment({
    organizationSlug,
    propertySlug,
    name: environmentName,
  })

  return { loading, currentEnvironment, currentActorEnvironmentRole }
}
