import { PaletteOptions } from '@mui/material'
import { purple, red, yellow } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'
import { cacheStatusColors, customColors, primaryColors, webVitalsColors } from '../customColors'

const basePalette: PaletteOptions = {
  common: {
    black: customColors.Jet,
    white: customColors.White,
  },
  primary: primaryColors,
  secondary: {
    main: customColors.SilverSand,
    light: purple[400],
    dark: customColors.BleuDeFrance,
    contrastText: customColors.White,
  },
  error: {
    main: customColors.PastelRed,
  },
  success: primaryColors,
  warning: {
    main: yellow[500],
    light: yellow[300],
    dark: yellow[700],
    contrastText: '#fff',
  },
  text: {
    disabled: customColors.SilverSand,
  },
  background: {
    paper: customColors.Jet,
  },
  action: {
    disabled: customColors.SilverSand,
    hover: alpha(primaryColors.main, 0.05),
    selected: alpha(primaryColors.main, 0.1),
  },
  danger: {
    main: red['A400'],
    light: red[300],
    dark: red[700],
    contrastText: '#fff',
  },
  cacheStatus: cacheStatusColors,
  webVitals: webVitalsColors,
  linkButtonColor: {
    main: '#01B18D',
  },
  dual: {
    main: primaryColors.main,
  },
}

export default basePalette
