import { useEffect } from 'react'
import Cookies from 'js-cookie'
import Loading from '../Loading'
import NotFound from '../NotFound'
import { useCurrentOrganization } from '../hooks/useCurrentOrganization'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useOrganizationDeletedSubscription } from '../organization/organizationQueries'
import { useAppStore } from '../stores/appStore/appStore'

export const OrganizationPage = ({ children }) => {
  const { loading, currentOrganization } = useCurrentOrganization()
  const { currentUser } = useCurrentUser()
  const { oneUiMode } = useAppStore((s) => ({ oneUiMode: s.oneUiMode }))

  useEffect(() => {
    if (currentOrganization?.slug) {
      // Note that this cookie is also used as a flag to detect that a user already signed-in in the past
      // so that he does not land on the signup page. For that use case, we want to keep a very long TTL here.
      Cookies.set(`${currentUser.id}_lastVisitedOrganization`, currentOrganization.slug, {
        expires: 365,
      })
    }
  }, [currentOrganization?.slug, currentUser?.id])

  useOrganizationDeletedSubscription(currentOrganization)

  if (loading) {
    return <Loading />
  }

  // Bypass notfound for One UI mode, since an org won't exist
  if (!currentOrganization && !oneUiMode) {
    return <NotFound />
  }

  return children
}
