import { makeStyles } from 'tss-react/mui'
import { lighten } from '@mui/material'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  sidebarFooter: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.default, 0.02)
        : theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.mode === 'dark' ? '#17232E' : '#ECEDEE'}`, // t-92 grey
    padding: '20px',
    cursor: 'pointer',
  },
}))

export const SidebarFooter = ({ children }: any) => {
  const { classes } = useStyles()
  return <div className={classes.sidebarFooter}>{children}</div>
}
