import { Theme } from '@mui/material'
import { Components } from '@mui/material'
import lightPaletteOptions from '../light/palette'

const baseComponentsOverrides: Components<Omit<Theme, 'components'>> = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: '0.875rem',
        lineHeight: 1.43,
      },
      a: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        paddingBottom: 0,
      },
      subheader: {
        marginTop: 8,
        fontSize: '0.875rem',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minWidth: 90,
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      message: {
        color: 'white',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        minHeight: 38, // match large button height
        borderColor: '#DCDEE0',
      },
      formControl: {
        fontWeight: 400,
      },
      input: {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.4,
        paddingTop: 10,
        paddingBottom: 10,
        '&::placeholder': {
          color: 'white',
          fontWeight: '400 !important',
        },
      },
      multiline: {
        height: 'auto',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: '72px',
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        border: '1px solid #DCDEE0',
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      sizeSmall: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          paddingLeft: 14,
        },
      },
      popupIndicator: {
        opacity: 0.6,
        '.MuiAutocomplete-endAdornment &': {
          marginRight: -2,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontWeight: '400 !important',
        fontSize: '0.875rem',
        lineHeight: 1.2,
        // color: '#687077', TODO: update from palette when design will be ready (incl dark theme)
      },
      asterisk: {
        color: '#01B18D', // TODO: update from palette when design will be ready (incl dark theme)
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        alignItems: 'center',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        // @ts-ignore
        borderColor: lightPaletteOptions.borderColor.main,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        height: '22px',
        padding: '9px 14px 7px 14px',
      },
    },
  },
}

export default baseComponentsOverrides
