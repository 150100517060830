import { makeStyles } from 'tss-react/mui'
import { Theme, useMediaQuery } from '@mui/material'

const useStyles = makeStyles<{ mobile: boolean }>()((theme: Theme, { mobile }) => ({
  sidebarHeader: {
    padding: mobile ? theme.spacing(1.6, 1.6, 1.6, 1.6) : theme.spacing(1, 1, 1, 1.6),
    backgroundColor: theme.palette.mode === 'dark' ? '#070A0E' : theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? '#17232E' : '#ECEDEE'}`, // t-92 grey
    height: '64px',
    boxSizing: 'border-box',
  },
}))

export const SidebarHeader = ({ children }: any) => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { classes } = useStyles({ mobile })
  return <div className={classes.sidebarHeader}>{children}</div>
}
