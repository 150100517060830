import { makeStyles } from 'tss-react/mui'
import { Theme, useMediaQuery } from '@mui/material'

const useStyles = makeStyles<{ mobile: boolean }>()((theme: Theme, { mobile }) => ({
  sidebar: {
    width: mobile ? '64px' : '246px',
    backgroundColor: '#fff',
    borderRight: `1px solid ${theme.palette.mode === 'dark' ? '#17232E' : '#ECEDEE'}`, // t-92 grey
    gridArea: 'sidebar',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& .MuiAccordionSummary-expandIconWrapper svg': {
      height: 20,
      width: 20,
    },
  },
}))

export const Sidebar = ({ children }: any) => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const { classes } = useStyles({ mobile })

  return <div className={classes.sidebar}>{children}</div>
}
