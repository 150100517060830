import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { FormControlLabel, Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    '.danger &': {
      color: theme.palette.error.main,
    },
  },
}))

interface Props {
  control: JSX.Element
  danger?: boolean
  label?: string | JSX.Element
}

export function ControlLabel({ control, danger = false, label }: Props) {
  const { classes } = useStyles()

  return (
    <FormControlLabel
      classes={classes}
      className={danger ? 'danger' : ''}
      control={control}
      label={label}
    />
  )
}
