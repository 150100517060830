import { makeStyles } from 'tss-react/mui'
import { Theme, useMediaQuery } from '@mui/material'

const useStyles = makeStyles<{ mobile: boolean }>()((_theme, { mobile }) => ({
  layout: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
          "sidebar header"
          "${mobile ? 'main' : 'sidebar'} main"
      `,
    minHeight: '100vh',
    ['& > *']: {
      boxSizing: 'border-box',
    },
    overflow: mobile ? 'auto' : 'hidden',
    height: '100vh',
  },
  mobileLayout: {},
}))

export const Layout = ({ children }: any) => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { classes } = useStyles({ mobile })

  return <div className={classes.layout}>{children}</div>
}
