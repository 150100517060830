import { makeStyles } from 'tss-react/mui'
import { lighten } from '@mui/material'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    backgroundColor: lighten(theme.palette.background.default, 0.02),
    gridArea: 'main',
    padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    // the Y scrollbar on filters sidebar lies on top of this components scrollbar
    overflowY: 'auto',
    display: 'block !important',
  },
}))

export const Main = ({ children }: any) => {
  const { classes } = useStyles()

  return <main className={classes.main}>{children}</main>
}
