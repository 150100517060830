export const Colors = {
  purple: {
    s70: '#270C2B',
    s50: '#401449',
    s30: '#5A1D66',
    s20: '#672174',
    s10: '#742582',
    100: '#503CA0',
    t15: '#9449A2',
    t25: '#AD74B8',
    t35: '#AD74B8',
    t50: '#C094C8',
    t65: '#D3B4D9',
    t75: '#DFC9E3',
    t85: '#ECDFEF',
    t92: '#F5EEF6',
    t96: '#FAF6FB',
    t98: '#FDFBFD',
  },
  grey: {
    s70: '#070A0E',
    s50: '#0C1117',
    s30: '#101820',
    s20: '#121C25',
    s10: '#151F29',
    100: '#323C50',
    t15: '#3A444D',
    t25: '#515A62',
    t35: '#687077',
    t50: '#8B9197',
    t65: '#AEB2B6',
    t75: '#C5C8CB',
    t85: '#DCDEE0',
    t92: '#ECEDEE',
    t96: '#F6F6F7',
    t98: '#FAFBFB',
  },
  blue: {
    s70: '#003144',
    s50: '#005171',
    s30: '#00719E',
    s20: '#0082B5',
    s10: '#0092CB',
    100: '#167ED0',
    t15: '#26B0E6',
    t25: '#40B9E9',
    t35: '#59C3EC',
    t50: '#80D0F1',
    t65: '#A6DEF5',
    t75: '#BFE8F8',
    t85: '#D9F1FB',
    t92: '#EBF8FD',
    t96: '#F5FBFE',
    t98: '#FAFDFE',
  },
  green: {
    s70: '#00352A',
    s50: '#005946',
    s30: '#017C63',
    s20: '#018E71',
    s10: '#019F7F',
    100: '#27AB66',
    t15: '#27BD9E',
    t25: '#40C4A9',
    t35: '#5ACCB5',
    t50: '#80D8C6',
    t65: '#A6E4D7',
    t75: '#BFECE2',
    t85: '#D9F3EE',
    t92: '#EBF9F6',
    t96: '#F5FCFA',
    t98: '#FAFDFD',
  },
  yellow: {
    s70: '#FFA54E',
    s50: '#FFAD53',
    s30: '#FFB458',
    s20: '#FFC462',
    s10: '#FFD46B',
    100: '#FFE65A',
    t15: '#FFEE8E',
    t25: '#FFF09B',
    t35: '#FFF2A9',
    t50: '#FFF5BD',
    t65: '#FFF8D0',
    t75: '#FFFADE',
    t85: '#FFFCEB',
    t92: '#FFFDF4',
    t96: '#FFFEFA',
    t98: '#FFFFFC',
  },
  orange: {
    s70: '#4C2F16',
    s50: '#804F25',
    s30: '#B36E33',
    s20: '#CC7E3A',
    s10: '#E58D42',
    100: '#FF963C',
    t15: '#FFAC64',
    t25: '#FFBF89',
    t35: '#f5c291',
    t50: '#FFCEA4',
    t65: '#FFDDBF',
    t75: '#FFE6D2',
    t85: '#FFF0E4',
    t92: '#FFF7F0',
    t96: '#FFFBF8',
    t98: '#FFFDFB',
  },
  red: {
    s70: '#420F0F',
    s50: '#6E1919',
    s30: '#9A2323',
    s20: '#B02828',
    s10: '#C62D2D',
    100: '#DC3232',
    t15: '#E15151',
    t25: '#E56565',
    t35: '#E87A7A',
    t50: '#EE9999',
    t65: '#F3B7B7',
    t75: '#f0cdcd',
    t85: '#FAE0E0',
    t92: '#FCEFEF',
    t96: '#FEF7F7',
    t98: '#FEFBFB',
  },
  pink: {
    s70: '#471828',
    s50: '#772842',
    s30: '#A7375D',
    s20: '#BE3F6A',
    s10: '#D64778',
    100: '#DD326C',
    t15: '#F16997',
    t25: '#F27BA3',
    t35: '#F48DB0',
    t50: '#F7A7C2',
    t65: '#F9C1D4',
    t75: '#FBD3E0',
    t85: '#FCE5ED',
    t92: '#FEF1F5',
    t96: '#FEF8FA',
    t98: '#FFFCFD',
  },
}
