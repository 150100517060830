import { TypographyVariantsOptions } from '@mui/material'
import { customColors } from '../customColors'

const darkTypography: TypographyVariantsOptions = {
  h1: {
    color: customColors.White,
  },
  subtitle1: {
    color: customColors.White,
  },
  body1: {
    color: customColors.White,
  },
  body2: {
    color: customColors.White,
  },
  caption: {
    color: customColors.White,
  },
  // @ts-ignore - for some reason code type override is not seen here
  code: {
    backgroundColor: '#0A0E12',
  },
}

export default darkTypography
