import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { useRouter } from 'next/router'
import { List } from '@mui/material'
import { Layers } from 'icons'
import pick from 'lodash/pick'
import { Link } from '../../../Link'
import { NavListItem } from '../../molecules'

const useStyles = makeStyles()((theme) => ({
  navListItem: {
    height: 36,
    padding: theme.spacing(0.5, 2),
    '& .MuiTypography-root': {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
}))

export const NavLink = ({ href, label, Icon, persistedParams = undefined, newTab = false }) => {
  const { asPath, query } = useRouter()
  const { classes } = useStyles()

  const item = (
    <NavListItem
      button
      selected={asPath === href}
      icon={<Icon height={18} width={18} />}
      className={classes.navListItem}
      classes={{
        root: classes.navListItem,
      }}
    >
      {label}
    </NavListItem>
  )

  if (href) {
    return (
      <Link
        className={classes.link}
        underline="none"
        href={
          href?.pathname || href.match(/^https?:/)
            ? href
            : { pathname: href, query: pick(query, persistedParams) }
        }
        newTab={newTab}
      >
        {item}
      </Link>
    )
  } else {
    return item
  }
}

NavLink.defaultProps = {
  Icon: Layers,
}

export const ParentNavLink = ({ href, label, persistedParams, Icon }) => {
  const { asPath, query } = useRouter()
  const { classes } = useStyles()

  return (
    <Link
      underline="none"
      href={{ pathname: href, query: pick(query, persistedParams) }}
      className={classes.link}
    >
      <NavListItem
        button
        selected={asPath.split('?')[0] === href}
        icon={<Icon height={18} width={18} />}
      >
        {label}
      </NavListItem>
    </Link>
  )
}

ParentNavLink.defaultProps = {
  Icon: Layers,
  persistedParams: [],
}

export const ChildNavLink = ({
  href = undefined,
  style = undefined,
  label,
  disabled = false,
  persistedParams = [],
  Icon,
  params = {},
  ...props
}) => {
  const { asPath, query } = useRouter()
  const { classes } = useStyles()
  const nav = (
    <NavListItem
      button
      style={style}
      disabled={disabled}
      isNested={true}
      selected={asPath.split('?')[0] === href}
      icon={Icon && <Icon height={18} width={18} />}
      {...props}
    >
      {label}
    </NavListItem>
  )

  if (href) {
    return (
      <Link
        underline="none"
        href={{ pathname: href, query: { ...pick(query, persistedParams), ...params } }}
        className={classes.link}
      >
        {nav}
      </Link>
    )
  } else {
    return nav
  }
}

ChildNavLink.defaultProps = {
  Icon: Layers,
  disabled: false,
  style: undefined,
  persistedParams: [],
}

export const VerticalNestedNav = () => {
  // const [open, setOpen] = React.useState(true)
  // const [selectedIndex, setSelectedIndex] = React.useState(0)

  const router = useRouter()

  return (
    <List component="nav">
      <NavLink href={`/${router.query.organizationSlug}/overview`} label="Overview" />
      <NavLink href={`/${router.query.organizationSlug}/activity`} label="Activity" />
      <NavLink href={`/${router.query.organizationSlug}/settings`} label="Settings" />
      <NavLink href={`/${router.query.organizationSlug}/rules`} label="Rules Demo" />
      {/* <NavListItem
        button
        selected={selectedIndex === 2}
        onClick={(event) => {
          handleListItemClick(event, 2)
          handleClick()
        }}
        icon={<Sliders fontSize="small" />}
        expandedIcon={open ? <ExpandLess /> : <ExpandMore />}
      >
        Collapsible Nav
      </NavListItem> */}
      {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavListItem
            button
            isNested={true}
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            Active Child
          </NavListItem>
          <NavListItem button isNested={true} disabled={true}>
            Inactive Child
          </NavListItem>
        </List>
      </Collapse> */}
    </List>
  )
}
