import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ShowError from './ShowError'
import airbrake from './airbrake'

/**
 * Catches errors during rendering and fallback component
 */
export default class ErrorBoundary extends PureComponent {
  constructor() {
    super()
    this.state = {}
  }

  componentDidCatch(error, info) {
    let sentError = error
    // send an Error object with a JSON stringified message if thrown `error` does not override `toString`
    if (error.toString === Object.prototype.toString) {
      sentError = new Error(JSON.stringify(error))
    }
    airbrake.notify({
      error: sentError,
      params: { info },
    })
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  clearError() {
    // clear the error when navigating on the client.
    this.setState({ error: undefined })
  }

  render() {
    let contents = this.props.children

    if (this.state.error) {
      // You can render any custom fallback UI
      contents = <ShowError error={this.state.error} message={this.props.message} />
    }

    return contents
  }
}

ErrorBoundary.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

ErrorBoundary.defaultProps = {
  message: (
    <div style={{ opacity: 0.6 }}>This section could not be loaded. Please try again later.</div>
  ),
}
