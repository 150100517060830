import { makeStyles } from 'tss-react/mui'
import { IconButton as MuiIconButton } from '@mui/material'
// @ts-ignore
import TrashIcon from '../../../../icons/trash.svg'
import { TIconButton } from '../types'

const useStyles = makeStyles()(() => ({
  root: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
}))

export default function DeleteButton(props: TIconButton) {
  const { classes } = useStyles()

  return (
    <MuiIconButton
      // @ts-ignore
      color="inherit"
      size="small"
      {...props}
      classes={{ ...classes, ...props.classes }}
    >
      <TrashIcon style={{ height: 16, width: 16 }} />
    </MuiIconButton>
  )
}
