import { Ref, forwardRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { darken, lighten } from '@mui/material/styles'

const useStyles = makeStyles<void, 'sizeLarge' | 'outlinedPrimary' | 'disabled'>()(
  (theme: Theme, _params, classes) => ({
    root: {
      height: '1.75rem',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      ...(theme.typography.body2 as { fontSize: string; color: string }),
      fontWeight: 500,
      color: 'white',
    },
    contained: {
      background: 'linear-gradient(89.92deg, #00BDA6 0%, #00A2E2 132.46%)',
      '&:hover': {
        background: 'linear-gradient(89.77deg, #00A2E2 -6.72%, #01B18D 138.89%)',
      },
      boxShadow: 'none',
    },
    containedSecondary: {
      background: 'linear-gradient(89.86deg, #812991 -1.31%, #00A2E2 138.3%)',
      '&:hover': {
        background: 'linear-gradient(89.86deg, #812991 -1.31%, #00A2E2 138.3%)',
      },
    },
    containedDanger: {
      background: `${theme.palette.danger.main} !important`,
      color: theme.palette.common.white,
      '&:hover, &:active': {
        background: `${theme.palette.danger.light} !important`,
      },
    },
    outlined: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.borderColor.main,
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#01B18D',
      [`&.${classes.sizeLarge}`]: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      [`&:not(.${classes.outlinedPrimary}):not(.${classes.disabled})`]: {
        color:
          theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
      },
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0, 0.2)',
        borderColor: (theme.palette.mode === 'dark' ? lighten : darken)(
          theme.palette.borderColor.main,
          0.2,
        ),
      },
    },
    outlinedSecondary: {
      borderColor: theme.palette.mode === 'dark' ? '#01B18D' : '#00A2E2',
      color: '#01B18D',
      [`&:not(.${classes.outlinedPrimary}):not(.${classes.disabled})`]: {
        color: theme.palette.mode === 'dark' ? '#01B18D' : '#00A2E2',
      },
      '&:hover': {
        borderColor: theme.palette.mode === 'dark' ? '#01B18D' : '#00A2E2',
      },
    },
    sizeLarge: {
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.typography.button.fontSize,
      height: '2.375rem',
      fontWeight: 'bold',
    },
    disabled: {
      opacity: 0.6,
    },
    outlinedPrimary: {},
    outlinedDanger: {
      color: `${theme.palette.danger.main} !important`,
      borderColor: theme.palette.danger.main,
      '&:hover, &:active': {
        background: 'inherit',
        borderColor: theme.palette.danger.light,
        color: `${theme.palette.danger.light} !important`,
      },
    },
    outlinedDual: {
      borderColor: theme.palette.text.primary,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.action.disabledBackground : '#F3F3F3',
        borderColor: theme.palette.text.primary,
      },
    },
    textPrimary: {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#01B18D',
    },
  }),
)

interface CaptchaProps {
  className: string
  'data-sitekey': string
  'data-action': string
  'data-callback': string
}

export interface Props {
  style?: any
  /** Used to pass on props necessary for captcha. */
  captchaProps?: CaptchaProps
  /** The content of the button. */
  children?: string | JSX.Element | string[]
  /** The color of the component. It supports those theme colors that make sense for this component. */
  color?: 'primary' | 'secondary' | 'dual' | 'danger'
  /** If `true`, the button will be disabled. */
  disabled?: boolean
  /** Element placed after the children. */
  endIcon?: JSX.Element
  /** If `true`, button will always be outlined, independent of theme. */
  forceOutlined?: boolean
  /** If `true`, the button will take up the full width of its container. */
  fullWidth?: boolean
  /**
   * @ignore
   * Used only by the `Link` component
   */
  href?: string
  /** If `true`, button will be large */
  large?: boolean
  /** Action that is called on the button click. */
  onClick?: () => void
  /** Element placed before the children. */
  startIcon?: JSX.Element
  /** The type of the button. */
  type?: 'button' | 'submit'
  /** The variant to use. */
  variant?: 'outlined' | 'contained' | 'text'
  autoFocus?: boolean
  /** For links, set this to true to open the page in a new browser tab */
  newTab?: boolean
  /** QA test hook */
  ['data-qa']?: string
  className?: string
  component?: string
  target?: string
}

/**
 * A button implementation that supports all colors in the theme's palette.
 */
export const Button = forwardRef(
  (
    {
      captchaProps,
      children,
      color = 'primary',
      disabled = false,
      endIcon,
      forceOutlined = false,
      fullWidth = false,
      href,
      large,
      onClick,
      startIcon,
      type = 'button',
      variant = 'outlined',
      autoFocus = false,
      style,
      className,
      target,
      ...props
    }: Props,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const { classes } = useStyles()

    return (
      // @ts-ignore
      <MuiButton
        style={style}
        classes={classes}
        className={className}
        // @ts-ignore
        color={color}
        disabled={disabled}
        endIcon={endIcon}
        fullWidth={fullWidth}
        href={href}
        onClick={onClick}
        ref={ref}
        size={large ? 'large' : 'medium'}
        startIcon={startIcon}
        type={type}
        variant={forceOutlined ? 'outlined' : variant}
        autoFocus={autoFocus}
        data-qa={props['data-qa']}
        // @ts-ignore
        component={props.component}
        target={target}
        {...captchaProps}
      >
        {children}
      </MuiButton>
    )
  },
)

Button.displayName = 'Button'
