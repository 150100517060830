import { withStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'

export const LinkButton = withStyles(Button, () => ({
  root: {
    color: '#01B18D',
    fontWeight: 500,
    fontSize: '0.875rem',

    ['& svg']: {
      fontSize: '1.125rem !important', // TODO: update when got more sizes in design
    },
  },
}))
