import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import type { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { useCurrentOrganization } from '../../../../hooks/useCurrentOrganization'
import { useDualQueryState } from '../../../../util'
import fetchAsmData from '../fetchAsmData'
import { ASM_PERMISSIONS, AsmPermission, AsmPermissionsBooleanMap } from '../types'

type User = {
  id: string
  fullname: string
  email: string
  avatar_url: string
}

type Collection = {
  id: string
  name: string
}

interface AsmContextDataType extends AsmPermissionsBooleanMap {
  collections: Collection[]

  /**
   * The slug of the current organization. Needed here to check
   * which organization this context belongs to.
   */
  currentOrganizationSlug: string

  /**
   * True if ASM is enabled for the current organization.
   */
  enabled: boolean

  /**
   * True if ASM should be shown by default after logging in.
   */
  showByDefault: boolean

  /**
   * The the array of all available permissions of currently logged in user.
   * @example ['asm_read', 'asm_scan']
   */
  permissions: AsmPermission[]

  /**
   * Returns true if the ASM has already been configured
   * for the current organization.
   */
  configured: boolean

  /**
   * Returns true if the context is still loading.
   */
  loading: boolean

  /**
   * The possible statuses of an exposure.
   */
  statuses: ExposureStatus[]

  /**
   * All of the users in the organization.
   */
  users: User[]

  /**
   * The maximum number of assets that can be under management.
   */
  max_assets_under_management: number

  /**
   * The number of assets currently under management.
   */
  assets_under_management: number

  /**
   * The active global collection filter.
   */
  globalCollectionFilter: string[]

  /**
   * The date when the exposure is due.
   */
  exposure_due_day_count: number

  /**
   * Sets the active global collection filter.
   */
  setGlobalCollectionFilter: (value: string[]) => void
}

export interface AsmContextType extends AsmContextDataType {
  reloadAsmContext: () => Promise<void>
}

export type ExposureStatus = {
  id: string
  name: string
  order: number
}

const defaults = {
  currentOrganizationSlug: null,
  collections: [],
  enabled: false,
  showByDefault: false,
  permissions: [],
  configured: false,
  loading: true,
  statuses: [],
  users: [],
  canRead: false,
  canScan: false,
  canComment: false,
  canManage: false,
  reloadAsmContext: async () => {},
  max_assets_under_management: 0,
  assets_under_management: 0,
  exposure_due_day_count: 0,
  globalCollectionFilter: [],
  setGlobalCollectionFilter: () => {},
}

/**
 * The AsmContext
 * for all Asm related components.
 */
export const AsmContext = createContext<AsmContextType>(defaults)

/**
 * The AsmContextProvider
 * provides context for all Asm related components.
 * @example const { loading, isConfigured, canManage } = useContext(AsmContext)
 */
export const AsmContextProvider = ({ children }: { children: ReactNode }) => {
  const { currentOrganization, loading: orgLoading } = useCurrentOrganization()

  const {
    queryState: { collection_id },
  } = useDualQueryState()

  const getCollectionsArray = () =>
    ((collection_id as string) || '').split(',').filter((v) => v.length > 0)

  const [globalCollectionFilter, setGlobalCollectionFilter] = useState<string[]>(
    getCollectionsArray(),
  )

  useEffect(() => {
    setGlobalCollectionFilter(getCollectionsArray())
  }, [collection_id])

  const [context, setContext] = useState<AsmContextDataType>({
    ...defaults,
    globalCollectionFilter,
    setGlobalCollectionFilter,
  })

  useEffect(() => {
    setContext((context) => ({ ...context, globalCollectionFilter }))
  }, [globalCollectionFilter])

  const fetchContext = useCallback(async () => {
    try {
      setContext((context) => ({ ...context, loading: true }))

      const res = await fetchAsmData(`/context?organization_id=${currentOrganization.id}`)

      if (res.ok) {
        const data = await res.json()

        // Create a boolean map
        // of all available permissions of currently logged in user
        // @example { canRead: true, canScan: false... }
        const permissions = Object.fromEntries(
          Object.entries(ASM_PERMISSIONS).map(([key, value]) => [
            `can${key}`,
            data.permissions.includes(value),
          ]),
        ) as AsmPermissionsBooleanMap

        setContext((context) => ({
          ...context,
          ...data,
          ...permissions,
          currentOrganizationSlug: currentOrganization.slug,
        }))
      }
      if (res.status === 403) {
        // When user switch to organization where he/she has no permissions,
        // the context should be reset to defaults,
        // so ASM disappears from the sidebar.
        setContext(defaults)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setContext((context) => ({ ...context, loading: false }))
    }
  }, [currentOrganization])

  const { replace, pathname } = useRouter()

  // Fetch available permissions of currently logged in user
  useEffect(() => {
    if (currentOrganization) {
      fetchContext()
    }
  }, [currentOrganization])

  useEffect(() => {
    if (orgLoading || context.loading) return
    if (!currentOrganization) return
    if (currentOrganization && currentOrganization.slug !== context.currentOrganizationSlug) {
      return
    }

    const permissionDeniedPath = `/${context.currentOrganizationSlug}/security/asm/permission_denied`

    if (
      pathname.includes('/security/asm') &&
      pathname !== permissionDeniedPath &&
      !context.loading &&
      (context.canRead === false || !context.enabled)
    ) {
      replace(permissionDeniedPath)
    }
  }, [context, pathname, currentOrganization, orgLoading])

  const asmContext = useMemo(() => {
    return { ...context, reloadAsmContext: fetchContext }
  }, [context, fetchContext])

  return <AsmContext.Provider value={asmContext}>{children}</AsmContext.Provider>
}

export default AsmContext
