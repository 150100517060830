import React, { useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import {
  drawerWidth,
  drawerWidthLg,
  drawerWidthMd,
  drawerWidthSM,
  drawerWidthXS,
} from '../constants'
import { TDrawer, TDrawerHeader } from '../types'
import { DrawerHeader } from './DrawerHeader'

const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    opacity: 1,
  },
  small: {
    width: drawerWidth,
  },
  medium: {
    width: drawerWidthMd,
    maxWidth: theme.breakpoints.values.md,
  },
  large: {
    width: drawerWidthLg,
    maxWidth: drawerWidthLg,
  },
  smallMedium: {
    width: drawerWidthSM,
  },
  drawerPaper: {
    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
      width: drawerWidthXS,
    },
  },
  drawerContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2.5),
    gap: theme.spacing(2),
    overflowY: 'auto',
  },
  drawerFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    gap: theme.spacing(2),
  },
  background: {
    zIndex: 10,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}))

export const Drawer = ({
  title,
  anchor,
  toggleDrawer = () => {},
  isOpen,
  children,
  onClose,
  size,
}: TDrawer & TDrawerHeader) => {
  const { classes, cx } = useStyles()

  // Close drawer on escape key press
  useEffect(() => {
    const listener = (event: any) => {
      if (event.key === 'Escape' && isOpen) {
        onClose()
      }
    }
    document.addEventListener('keyup', listener)
    return () => document.removeEventListener('keyup', listener)
  }, [onClose, isOpen])

  return (
    <React.Fragment key={anchor}>
      <SwipeableDrawer
        anchor={anchor}
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: cx({
            [classes.drawerPaper]: true,
            [classes.medium]: size === 'medium',
            [classes.large]: size === 'large',
            [classes.small]: size === 'small',
            [classes.smallMedium]: size === 'smallMedium',
          }),
        }}
      >
        <DrawerHeader title={title} onClose={onClose} />
        {children}
      </SwipeableDrawer>
      {isOpen && <div onClick={onClose} className={classes.background} />}
    </React.Fragment>
  )
}

const DContent = ({
  children,
  customClasses,
}: {
  children: React.ReactNode
  customClasses?: { [name: string]: any }
}) => {
  const { classes } = useStyles(undefined, {
    props: {
      classes: customClasses,
    },
  })
  return <div className={classes.drawerContent}>{children}</div>
}
const DFooter = ({ children }: { children: React.ReactNode }) => {
  const { classes } = useStyles()

  return <footer className={classes.drawerFooter}>{children}</footer>
}

Drawer.defaultProps = {
  anchor: 'right',
  size: 'small',
}

Drawer.Content = DContent
Drawer.Footer = DFooter
