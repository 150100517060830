import { useEffect, useState } from 'react'
import { useNotifications } from '../../../util'
import restFetch from '../../../util/rest'

export default function fetchAsmData(uri: string, record?: any, options: RequestInit = {}) {
  return restFetch(`/api/asm/v0.1${uri}`, record, options)
}

export type FetchState<T> = {
  loading: boolean
  data: T
  error: boolean
  errorMessage?: string
}

export function useAsmData<T>(
  uri: string,
  params: any,
  notify = true,
  refreshKey?: any,
): FetchState<T> {
  const [state, setState] = useState<FetchState<T>>({
    loading: true,
    data: null,
    error: false,
    errorMessage: null,
  })
  const notifications = useNotifications({})

  useEffect(() => {
    async function doFetch() {
      try {
        setState((state) => ({ ...state, loading: true }))
        const url = new URL(uri, window.location.origin)

        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            url.searchParams.append(key, value.toString())
          }
        })

        const res = await fetchAsmData(url.pathname + url.search)

        if (res.ok) {
          setState({ loading: false, data: await res.json(), error: false, errorMessage: null })
        } else {
          const { title, description } = await res.json()
          const errorMessage = `${title}: ${description}`
          setState({ loading: false, data: null, error: true, errorMessage })
          // We'll notify the user about the error by default
          if (notify) notifications.error(errorMessage)
        }
      } catch (e) {
        setState({ loading: false, data: null, error: true, errorMessage: e.message })
      }
    }

    doFetch()
  }, [uri, JSON.stringify(params), refreshKey])

  return state
}
