import { PaletteOptions, darken } from '@mui/material'
import { pink } from '@mui/material/colors'
import { Colors } from '../colors'
import { customColors, lightCacheStatusColors } from '../customColors'

const lightPaletteOptions: PaletteOptions = {
  mode: 'light',
  // @ts-ignore we are overwriting only light value from base palette
  primary: {
    light: pink[400],
  },
  // @ts-ignore we are overwriting only light value from base palette
  success: {
    light: pink[400],
  },
  text: {
    primary: customColors.EerieBlack,
    secondary: customColors.GraniteGray,
  },
  background: {
    paper: customColors.White,
    default: 'rgb(253, 253, 253)',
  },
  action: {
    active: customColors.EerieBlack,
    disabledBackground: customColors.Cultured,
  },
  divider: darken('#ECEDEE', 0.0),
  borderColor: {
    main: darken('#ECEDEE', 0.1),
  },
  danger: {
    main: '#ff1744',
    light: '#e57373',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  cacheStatus: lightCacheStatusColors,
  accordion: {
    border: darken('#ECEDEE', 0.05),
    summary: '#F6F6F7',
  },
  inputBackground: 'white',
  dialogBackground: 'white',
  tabsPanel: {
    border: '#DCDEE0',
    background: Colors.grey['t98'],
  },
  tabsNav: {
    background: 'white',
  },
}

export default lightPaletteOptions
