export const baseGlobalOverrides = {
  // a: {
  //   textDecoration: 'none',
  //   '&:hover': {
  //     textDecoration: 'underline',
  //   },
  // },
  '#root': {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  '#root .App': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  '#root .App main': {
    flex: 1,
    flexDirection: 'column',
    '& code': {
      padding: '3px 5px',
      borderWidth: 1,
      borderStyle: 'solid',
      whiteSpace: 'pre',
    },
  },
  [`input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration`]: {
    '-webkit-appearance': 'none',
  },
  [`input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus`]: {
    border: 'none',
    borderRadius: '0 !important',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg);' },
    '100%': { transform: 'rotate(360deg);' },
  },
  /* Customizing the scrollbar corner */
  '::-webkit-scrollbar-corner': {
    backgroundColor: '#0C1117',
  },
}
