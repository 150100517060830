import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import MuiInputLabel from '@mui/material/InputLabel'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    marginBottom: theme.spacing(0.75),
  },
}))

interface Props {
  children: string | JSX.Element
  id?: string
  required?: boolean
}

export function InputLabel({ children, id, required = false }: Props) {
  const { classes } = useStyles()

  return (
    <MuiInputLabel classes={classes} id={id} required={required}>
      {children}
    </MuiInputLabel>
  )
}
