import { useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import getValue from 'lodash/get'
import { currentUserQuery } from '../auth/authQueries'
import { PAGE_SIZE } from '../constants'
import { ORGANIZATION_VIEW } from '../organization/organizationQueries'
import { PROPERTY_SETTINGS_VIEW } from '../property/propertyQueries'
import { createArrayUpdater } from '../util'

const porpertyMemory = gql`
  query adminPropertyMemory {
    properties: adminPropertyMemory {
      id
      createdAt
      slug
      organization {
        id
        name
        slug
        awsAccount {
          id
          name
        }
      }
      currentMemorySize
    }
  }
`

export const usePropertyMemory = () => useQuery(porpertyMemory)

const adminEnablePropertyTraffic = gql`
  mutation adminEnablePropertyTraffic($propertyId: ID!) {
    adminEnablePropertyTraffic(propertyId: $propertyId) {
      property {
        ...PropertySettingsView
      }
      userErrors {
        message
      }
    }
  }
  ${PROPERTY_SETTINGS_VIEW}
`

export const useAdminEnablePropertyTrafficMutation = () => {
  const [mutate, mutationResult] = useMutation(adminEnablePropertyTraffic)

  return [
    async (propertyId) => {
      const { data } = await mutate({
        variables: { propertyId },
      })
      return data.adminEnablePropertyTraffic
    },
    mutationResult,
  ]
}

const adminDisablePropertyTraffic = gql`
  mutation adminDisablePropertyTraffic($propertyId: ID!) {
    adminDisablePropertyTraffic(propertyId: $propertyId) {
      property {
        ...PropertySettingsView
      }
      userErrors {
        message
      }
    }
  }
  ${PROPERTY_SETTINGS_VIEW}
`

export const useAdminDisablePropertyTrafficMutation = () => {
  const [mutate, mutationResult] = useMutation(adminDisablePropertyTraffic)

  return [
    async (propertyId) => {
      const { data } = await mutate({
        variables: { propertyId },
      })
      return data.adminDisablePropertyTraffic
    },
    mutationResult,
  ]
}

const ADMIN_USER_VIEW = gql`
  fragment AdminUserView on User {
    id
    email
    fullname
    staff
    staffRole
  }
`

const getAwsCosts = gql`
  query adminAwsCosts($startDate: String!, $endDate: String!) {
    costs: adminAwsCosts(startDate: $startDate, endDate: $endDate) {
      timestamp
      fixedcost
      incrementalcost
      pageviews
    }
  }
`

export const useAwsCosts = (startDate, endDate) =>
  useQuery(getAwsCosts, { variables: { startDate, endDate } })

const getAwsCostSummary = gql`
  query adminAwsCostSummary($startDate: String!, $endDate: String!) {
    summary: adminAwsCostSummary(startDate: $startDate, endDate: $endDate) {
      startdate
      enddate
      fixedcost
      incrementalcost
    }
  }
`

export const useAwsCostSummary = (startDate, endDate) =>
  useQuery(getAwsCostSummary, { variables: { startDate, endDate } })

const getRumHostSummary = gql`
  query adminRumHostSummary($startDate: String!, $endDate: String!) {
    hosts: adminRumHostSummary(startDate: $startDate, endDate: $endDate) {
      host
      pageviews
      posts
      percent
    }
  }
`

export const useRumHostSummary = (startDate, endDate) =>
  useQuery(getRumHostSummary, { variables: { startDate, endDate } })

const searchProperties = gql`
  query adminSearchPropertyList($offset: Int, $first: Int, $search: String) {
    properties: adminSearchProperties(offset: $offset, first: $first, search: $search) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        createdAt
        slug
        organization {
          id
          name
          slug
          awsAccount {
            id
            name
          }
        }
        activeDomains {
          id
          name
        }
      }
    }
  }
`

export const useSearchProperty = (search) => {
  const { data, loading, error, fetchMore, refetch } = useQuery(searchProperties, {
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: 0,
      first: PAGE_SIZE,
      search,
    },
  })

  const loadMoreProperties = () => {
    fetchMore({
      variables: {
        // always based the offset on the number of adminDomains in the cache.  This will remain consistent even when
        // adminDomains are deleted as long as they are removed from the cache.
        offset: data.properties.nodes.length,
        first: PAGE_SIZE,
        search,
      },
      updateQuery: ({ properties }, { fetchMoreResult }) => {
        if (!fetchMoreResult) return properties

        return {
          properties: {
            ...properties,
            nodes: [...properties.nodes, ...fetchMoreResult.properties.nodes],
            pageInfo: fetchMoreResult.properties.pageInfo,
          },
        }
      },
    })
  }

  return {
    error,
    loading,
    refetch,
    pageInfo: getValue(data, 'properties.pageInfo'),
    properties: getValue(data, 'properties.nodes', []),
    loadMoreProperties,
  }
}

const getMoovwebUsers = gql`
  query adminInternalUsers {
    users: adminInternalUsers {
      ...AdminUserView
    }
  }
  ${ADMIN_USER_VIEW}
`

export const useGetMoovwebUsers = () => useQuery(getMoovwebUsers)

const updateUser = gql`
  mutation updateUser($user: UpdateUserAttributes!) {
    updateUser(user: $user) {
      user {
        ...AdminUserView
      }
    }
  }
  ${ADMIN_USER_VIEW}
`

export const useSetStaffFlag = () => {
  const [saveUser] = useMutation(updateUser)

  return async (id, staffRole) =>
    saveUser({
      variables: {
        user: {
          id: id,
          staffRole: staffRole,
        },
      },
    })
}

const updateStaffSessionRole = gql`
  mutation adminUpdateSessionRole($role: String!) {
    adminUpdateStaffSessionRole(role: $role)
  }
`

export const useUpdateStaffSessionRole = () => {
  const [mutate] = useMutation(updateStaffSessionRole)

  return (role) =>
    mutate({
      variables: { role },
    })
}

const activateStaff = gql`
  mutation adminActivateStaff($cancel: Boolean) {
    adminActivateStaff(cancel: $cancel)
  }
`

export const useActivateStaff = () => {
  const [mutate] = useMutation(activateStaff)

  return async ({ cancel = false } = {}) => {
    const refetchQueries = [{ query: currentUserQuery }]

    return mutate({
      variables: { cancel },
      refetchQueries,
    })
  }
}

const CODE_BUILD_LOGS_VIEW = gql`
  fragment CodeBuildLogsView on CodeBuild {
    id
    status
    direction
    buildLogs {
      timestamp
      message
    }
    awsAccount {
      id
      name
      accountId
    }
    runByUser {
      id
      email
    }
  }
`

const AWS_ACCOUNT_VIEW = gql`
  fragment AwsAccountView on AdminAwsAccount {
    id
    name
    accountId
    state
    provisionerVersion
    provisionerImage
    free
    maxConcurrency
    minXbpContainers
    maxXbpContainers
    bufferProxyEnabled
    signInUrl
    organizations {
      id
      slug
    }
    xdnRegion {
      name
      primaryAwsRegion
      secondaryAwsRegion
    }
    lastCodeBuild {
      id
      buildAwsId
      status
      direction
      createdAt
      runByUser {
        id
        email
      }
    }
  }
`

const awsAccountsSubscription = gql`
  subscription adminAwsAccountsUpdated {
    adminAwsAccountsUpdated {
      new {
        ...AwsAccountView
      }
      updated {
        ...AwsAccountView
      }
      deleted
    }
  }
  ${AWS_ACCOUNT_VIEW}
`

const getCodeBuild = gql`
  query adminCodeBuild($id: ID!) {
    codeBuild: adminCodeBuild(id: $id) {
      ...CodeBuildLogsView
    }
  }
  ${CODE_BUILD_LOGS_VIEW}
`

export const useGetCodeBuildLogs = (id) => {
  return useQuery(getCodeBuild, { skip: !id, variables: { id } })
}

const getAwsAccounts = gql`
  query adminAwsAccounts {
    awsAccounts: adminAwsAccounts {
      ...AwsAccountView
    }
  }
  ${AWS_ACCOUNT_VIEW}
`

export const useGetAwsAccounts = () => {
  const { subscribeToMore, ...queryResults } = useQuery(getAwsAccounts)

  useEffect(
    () =>
      subscribeToMore({
        document: awsAccountsSubscription,
        variables: {},
        updateQuery: createArrayUpdater(
          (prev) => prev.awsAccounts,
          ({ subscriptionData: { data } }) => data.adminAwsAccountsUpdated,
        ),
      }),
    [],
  )

  return queryResults
}

const getXdnRegions = gql`
  query adminXdnRegions {
    xdnRegions: adminXdnRegions {
      id
      name
    }
  }
`

export const useGetXdnRegions = () => {
  return useQuery(getXdnRegions)
}

const provisionSubaccountMutation = gql`
  mutation adminProvisionSubaccount($awsAccountId: ID!, $imageOverride: String = null) {
    adminProvisionSubaccount(awsAccountId: $awsAccountId, imageOverride: $imageOverride)
  }
`

export const useProvisionSubaccountMutation = () => {
  const [mutate] = useMutation(provisionSubaccountMutation)

  return (awsAccountId, imageOverride) => mutate({ variables: { awsAccountId, imageOverride } })
}

const deprovisionSubaccountMutation = gql`
  mutation adminDeprovisionSubaccount($awsAccountId: ID!, $imageOverride: String = null) {
    adminDeprovisionSubaccount(awsAccountId: $awsAccountId, imageOverride: $imageOverride)
  }
`

export const useDeprovisionSubaccountMutation = () => {
  const [mutate] = useMutation(deprovisionSubaccountMutation)

  return (awsAccountId, imageOverride) => mutate({ variables: { awsAccountId, imageOverride } })
}

const updateSubaccountMutation = gql`
  mutation adminUpdateSubaccount($awsAccount: AdminUpdateSubaccountAttributes!) {
    adminUpdateSubaccount(awsAccount: $awsAccount) {
      awsAccount {
        ...AwsAccountView
      }
    }
  }
  ${AWS_ACCOUNT_VIEW}
`

export const useUpdateSubaccountMutation = () => {
  const [mutate] = useMutation(updateSubaccountMutation)

  return (awsAccount) => mutate({ variables: { awsAccount } })
}

const adminUpdateOrganization = gql`
  mutation adminUpdateOrganization($organization: AdminUpdateOrganizationAttributes!) {
    adminUpdateOrganization(organization: $organization) {
      organization {
        ...OrganizationView
      }
    }
  }
  ${ORGANIZATION_VIEW}
`

export const useUpdateOrganization = () => {
  const [mutate] = useMutation(adminUpdateOrganization)

  return (organization) => mutate({ variables: { organization } })
}

const adminDisableOrganization = gql`
  mutation adminDisableOrganization($organizationId: ID!) {
    adminDisableOrganization(organizationId: $organizationId) {
      organization {
        ...OrganizationView
      }
    }
  }
  ${ORGANIZATION_VIEW}
`

export const useAdminDisableOrganization = () => {
  const [mutate, result] = useMutation(adminDisableOrganization)

  return [(organizationId) => mutate({ variables: { organizationId } }), result]
}

const adminEnableOrganization = gql`
  mutation adminEnableOrganization($organizationId: ID!) {
    adminEnableOrganization(organizationId: $organizationId) {
      organization {
        ...OrganizationView
      }
    }
  }
  ${ORGANIZATION_VIEW}
`

export const useAdminEnableOrganization = () => {
  const [mutate, result] = useMutation(adminEnableOrganization)

  return [(organizationId) => mutate({ variables: { organizationId } }), result]
}

const appSettingsQuery = gql`
  query adminAppSettings {
    adminAppSettings {
      id
      key
      value
    }
  }
`

export const useAppSettingsQuery = () => useQuery(appSettingsQuery)

export const updateAppSetting = gql`
  mutation adminUpdateAppSetting($key: String!, $value: String!) {
    adminUpdateAppSetting(key: $key, value: $value) {
      appSetting {
        id
        key
        value
      }
      userErrors {
        message
      }
    }
  }
`

export const useUpdateAppSettingsMutation = () => {
  const [mutate] = useMutation(updateAppSetting)

  return ({ key, value }) => mutate({ variables: { key, value } })
}

const getProvisionerVersions = gql`
  query adminProvisionerVersions {
    adminProvisionerVersions {
      versions {
        id
        name
        tag
        image
      }
      warning
    }
  }
`

export const useProvisionerVersions = () => useQuery(getProvisionerVersions)

export const useGetDomainsExportTypesQuery = () =>
  useQuery(gql`
    query adminExportTypes {
      adminExportTypes {
        type
        label
        description
      }
    }
  `)
