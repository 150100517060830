import { TypographyVariantsOptions } from '@mui/material'
import { customColors } from '../customColors'

const lightTypography: TypographyVariantsOptions = {
  h1: {
    color: customColors.EerieBlack,
  },
  subtitle1: {
    color: customColors.GraniteGray,
  },
  body1: {
    color: customColors.EerieBlack,
  },
  body2: {
    color: customColors.EerieBlack,
  },
  caption: {
    color: customColors.GraniteGray,
  },
  // @ts-ignore - for some reason code type override is not seen here
  code: {
    backgroundColor: '#0A0E12',
  },
}
export default lightTypography
