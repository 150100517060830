import { useEffect } from 'react'
import { HOME_SNAPSHOT_VIEW } from '../build/BuildHomeSnapshotPanel'
import { gql, useMutation, useQuery } from '@apollo/client'
import getValue from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { DOMAIN_VIEW } from '../environment/environmentQueries'
import { useCurrentOrganization } from '../hooks/useCurrentOrganization'
import {
  getOrganization,
  getOrganizationPropertiesQuery,
} from '../organization/organizationQueries'
import { createArrayUpdater } from '../util'

const LATEST_BUILD_HOME_SNAPSHOT_VIEW = gql`
  fragment LatestBuildHomeSnapshotView on LatestBuildSnapshot {
    id
    url
    status
    httpStatusCode
  }
`

const LATEST_BUILD_VIEW = gql`
  fragment LatestBuildView on LatestBuild {
    id
    number
    status
    createdAt
    permalinkUrl
    xdnVersion
    homeSnapshot {
      ...LatestBuildHomeSnapshotView
    }
    environmentVersion {
      configSource
    }
    user {
      email
    }
  }
  ${LATEST_BUILD_HOME_SNAPSHOT_VIEW}
`

export const PROPERTY_VIEW = gql`
  fragment PropertyView on Property {
    id
    slug
    githubUrl
    framework
    hasRumData
    idsTenantId
    productionEnvironment {
      id
      name
    }
    activeDomains {
      name
      environment {
        name
        production
      }
    }
    latestBuild {
      ...LatestBuildView
    }
    createdAt
    updatedAt
  }
  ${LATEST_BUILD_VIEW}
`

export const ENVIRONMENT_LIST_VIEW = gql`
  fragment EnvironmentListView on Environment {
    id
    name
    default
    defaultDomainName
    httpRequestLogging
    apexIps
    mccHexId
    idsTenantId
    hostnames {
      hostname
      defaultOriginName
    }
    activeUrls
    production
    awsAccount {
      id
      free
    }
    activeVersion {
      id
      version
      configSource
    }
    activeBuild {
      id
    }
    latestBuild {
      id
      status
      number
      permalinkUrl
      xdnVersion
      createdAt
      branch {
        id
        name
      }
      homeSnapshot {
        ...homeSnapshotView
      }
      user {
        email
      }
    }
  }
  ${HOME_SNAPSHOT_VIEW}
`

export const getEnvironments = gql`
  query getEnvironments($propertyId: ID!) {
    environments: environmentsByProperty(
      propertyId: $propertyId
      orderBy: [{ default: desc }, { name: asc }]
    ) {
      nodes {
        ...EnvironmentListView
      }
    }
  }
  ${ENVIRONMENT_LIST_VIEW}
`

export const propertyEnvironmentsSubscription = gql`
  subscription getPropertyEnvironmentsSubscription($propertyId: ID!) {
    propertyEnvironmentsUpdated(propertyId: $propertyId) {
      new {
        ...EnvironmentListView
      }
      updated {
        ...EnvironmentListView
      }
      deleted
    }
  }
  ${ENVIRONMENT_LIST_VIEW}
`

// Environments list is sorted by getEnvironments query, but they may need to be resorted
// when updated by subscription
const sortEnvironments = (environments) => {
  return sortBy(environments, [(e) => !e.default, (e) => e.name])
}

export const useGetEnvironmentsQuery = (propertyId, options) => {
  const queryResults = useQuery(getEnvironments, {
    variables: { propertyId },
    ...options,
  })

  const { subscribeToMore } = queryResults

  useEffect(() => {
    if (propertyId) {
      return subscribeToMore({
        document: propertyEnvironmentsSubscription,
        variables: { propertyId },
        updateQuery: createArrayUpdater(
          (prev) => prev.environments,
          ({ subscriptionData: { data } }) => data.propertyEnvironmentsUpdated,
          { sortFn: sortEnvironments },
        ),
      })
    }
  }, [propertyId])

  return queryResults
}

const deleteProperty = gql`
  mutation deleteProperty($id: ID!) {
    deleteProperty(id: $id)
  }
`

export const useDeleteProperty = () => {
  const [mutate] = useMutation(deleteProperty)
  // Note that we don't need to refetch organization properties, it will be updated
  // by the subscription
  return (propertyId) => mutate({ variables: { id: propertyId } })
}

const deleteInactiveBuilds = gql`
  mutation deleteInactiveBuilds($id: ID!) {
    deleteInactiveBuilds(id: $id)
  }
`

export const useDeleteInactiveBuilds = () => {
  const [mutate] = useMutation(deleteInactiveBuilds)
  return (propertyId) => mutate({ variables: { id: propertyId } })
}

export const PROPERTY_SETTINGS_VIEW = gql`
  fragment PropertySettingsView on Property {
    id
    slug
    hasRumData
    maxMemorySize
    minMemorySize
    currentMemorySize
    defaultMemorySize
    trafficDisabledAt
    cloudFunctionsTimeoutSecondsDefault
    cloudFunctionsTimeoutSecondsMax
    cloudFunctionsTimeoutSecondsMin
    cloudFunctionsTimeoutSecondsCurrent
    permalinksEnvironmentTenantId
    permalinksEnvironmentHexId
    organization {
      id
      tier
    }
    activeDomains {
      ...DomainView
    }
  }
  ${DOMAIN_VIEW}
`

const getPropertySettings = gql`
  query property($organizationSlug: String!, $slug: String!) {
    property(organizationSlug: $organizationSlug, slug: $slug) {
      ...PropertySettingsView
    }
  }
  ${PROPERTY_SETTINGS_VIEW}
`

const getProperty = gql`
  query property($organizationSlug: String!, $slug: String!) {
    property(organizationSlug: $organizationSlug, slug: $slug) {
      ...PropertyView
    }
    currentActorPropertyRole(organizationSlug: $organizationSlug, slug: $slug) {
      actions {
        action
        subject
      }
      role
    }
  }
  ${PROPERTY_VIEW}
`

export const useGetProperty = ({ organizationSlug, propertySlug }) => {
  return useQuery(getProperty, {
    variables: { organizationSlug, slug: propertySlug },
    skip: !organizationSlug || !propertySlug,
  })
}

export const propertySubscription = gql`
  subscription getPropertyUpdated($propertyId: ID!) {
    propertyUpdated(propertyId: $propertyId) {
      new {
        ...PropertySettingsView
      }
      updated {
        ...PropertySettingsView
      }
      deleted
    }
  }
  ${PROPERTY_SETTINGS_VIEW}
`

export const useGetPropertySettings = (variables, options = {}) => {
  return useQuery(getPropertySettings, { variables, skip: !variables.organizationSlug, ...options })
}

const getPropertiesPciCompliance = gql`
  query propertiesPciCompliance(
    $organizationSlug: String!
    $offset: Int
    $first: Int
    $pciCompliance: Boolean
  ) {
    propertiesPciCompliance(
      organizationSlug: $organizationSlug
      orderBy: [{ slug: asc }, { environmentName: asc }]
      offset: $offset
      first: $first
      pciCompliance: $pciCompliance
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        slug
        environmentId
        environmentName
        environmentPciCompliance
      }
    }
  }
`

export const useGetPropertiesPciCompliance = (variables) => {
  const { data, loading, error, fetchMore } = useQuery(getPropertiesPciCompliance, {
    variables,
    skip: !variables.organizationSlug,
  })

  const loadMorePropertiesPciCompliance = () => {
    fetchMore({
      variables: {
        // always based the offset on the number of adminDomains in the cache.  This will remain consistent even when
        // adminDomains are deleted as long as they are removed from the cache.
        offset: data.propertiesPciCompliance.nodes.length,
        first: PAGE_SIZE,
      },
      updateQuery: ({ propertiesPciCompliance }, { fetchMoreResult }) => {
        if (!fetchMoreResult) return propertiesPciCompliance

        return {
          propertiesPciCompliance: {
            ...propertiesPciCompliance,
            nodes: [
              ...propertiesPciCompliance.nodes,
              ...fetchMoreResult.propertiesPciCompliance.nodes,
            ],
            pageInfo: fetchMoreResult.propertiesPciCompliance.pageInfo,
          },
        }
      },
    })
  }

  return {
    propertiesPciCompliance: getValue(data, 'propertiesPciCompliance.nodes', []),
    pageInfo: getValue(data, 'propertiesPciCompliance.pageInfo'),
    loading,
    error,
    loadMorePropertiesPciCompliance,
  }
}

const createGithubProperty = gql`
  mutation createGithubProperty(
    $propertySlug: String!
    $organizationSlug: String!
    $accessToken: String!
    $repositorySlug: String!
    $deployType: DeployEnum!
    $frameworkType: String
  ) {
    createGithubProperty(
      propertySlug: $propertySlug
      organizationSlug: $organizationSlug
      accessToken: $accessToken
      repositorySlug: $repositorySlug
      deployType: $deployType
      frameworkType: $frameworkType
    ) {
      property {
        ...PropertySettingsView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${PROPERTY_SETTINGS_VIEW}
`

export const useCreateGithubPropertyMutation = () => {
  const [mutate, mutationResult] = useMutation(createGithubProperty)

  return [
    async ({
      propertySlug,
      organizationSlug,
      accessToken,
      repositorySlug,
      deployType,
      frameworkType,
    }) => {
      const { data } = await mutate({
        variables: {
          propertySlug,
          organizationSlug,
          accessToken,
          repositorySlug,
          deployType,
          frameworkType,
        },
      })
      return data.createGithubProperty
    },
    mutationResult,
  ]
}

const updateProperty = gql`
  mutation updateProperty($property: UpdatePropertyAttributes!) {
    updateProperty(property: $property) {
      property {
        ...PropertySettingsView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${PROPERTY_SETTINGS_VIEW}
`

const updatePropertySsl = gql`
  mutation updatePropertySsl($property: UpdateSslAttributes!) {
    updatePropertySsl(property: $property) {
      property {
        ...PropertySettingsView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${PROPERTY_SETTINGS_VIEW}
`

export const useUpdatePropertyMutation = () => {
  const [mutate, mutationResult] = useMutation(updateProperty)

  return [
    async (property) => {
      const { data } = await mutate({
        variables: { property },
      })
      return data.updateProperty
    },
    mutationResult,
  ]
}

export const useUpdatePropertySslMutation = () => {
  const [mutate, mutationResult] = useMutation(updatePropertySsl)

  return [
    async (property) => {
      const { data } = await mutate({
        variables: { property },
      })
      return data.updatePropertySsl
    },
    mutationResult,
  ]
}

const moveProperty = gql`
  mutation moveProperty($propertyId: ID!, $organizationId: ID!) {
    moveProperty(propertyId: $propertyId, organizationId: $organizationId)
  }
`

export const useMovePropertyMutation = () => {
  const [mutate] = useMutation(moveProperty)

  return (propertyId, organizationId) => mutate({ variables: { propertyId, organizationId } })
}

const createProperty = gql`
  mutation createProperty(
    $property: CreatePropertyAttributes!
    $hostnames: [HostnameAttributes!]
    $origins: [JSON!]
    $rules: JSON
  ) {
    createProperty(property: $property, hostnames: $hostnames, origins: $origins, rules: $rules) {
      property {
        slug
      }
      userErrors {
        message
        path
        bypassable
      }
    }
  }
`

export const useCreateProperty = () => {
  const { currentOrganization } = useCurrentOrganization()

  const [mutate] = useMutation(createProperty)
  return (property, hostnames, origins, rules) =>
    mutate({
      variables: { property, hostnames, origins, rules },
      refetchQueries: [
        { query: getOrganization, variables: { slug: currentOrganization.slug } },
        { query: getOrganizationPropertiesQuery, variables: { slug: currentOrganization.slug } },
        {
          query: getProperty,
          variables: { slug: property.slug, organizationSlug: currentOrganization.slug },
        },
      ],
      awaitRefetchQueries: true,
    })
}

const sendOutPropertyAlertsMutation = gql`
  mutation sendAllPropertyAlerts($propertyId: ID!) {
    sendAllPropertyAlerts(id: $propertyId) {
      success
      userErrors {
        message
        path
      }
    }
  }
`

export const useSendOutPropertyAlertsMutation = () => {
  const [mutate, mutationResult] = useMutation(sendOutPropertyAlertsMutation)

  return [
    async (propertyId) => {
      const { data } = await mutate({ variables: { propertyId } })
      return data.sendAllPropertyAlerts
    },
    mutationResult,
  ]
}
