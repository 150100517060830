import { PaletteOptions, darken, lighten } from '@mui/material'
import { pink } from '@mui/material/colors'
import { Colors } from '../colors'
import { customColors, darkCacheStatusColors } from '../customColors'

const darkPaletteOptions: PaletteOptions = {
  mode: 'dark',
  common: {
    black: customColors.Jet,
    white: customColors.White,
  },
  // @ts-ignore we are overwriting only light value from base palette
  primary: {
    light: pink['A100'],
  },
  // @ts-ignore we are overwriting only light value from base palette
  success: {
    light: pink['A100'],
  },
  text: {
    primary: customColors.White,
    secondary: darken(customColors.White, 0.3),
  },
  background: {
    default: '#060A0E',
    paper: '#101820',
  },
  action: {
    active: customColors.White,
    disabledBackground: customColors.GraniteGray,
  },
  divider: '#17232E',
  cacheStatus: darkCacheStatusColors,
  accordion: {
    border: '#17232E',
    summary: '#17232E',
  },
  inputBackground: '#0D1117',
  dialogBackground: Colors.grey['s20'],
  tabsPanel: {
    border: '#17232E',
    background: Colors.grey['s30'],
  },
  tabsNav: {
    background: Colors.grey['s50'],
  },
  borderColor: {
    main: lighten('#17232E', 0.1),
  },
}

export default darkPaletteOptions
