import { useEffect, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { RegexHelpProvider } from '../src/rules/ui/editors/RegexHelpDialog'
import { CssBaseline } from '@mui/material'
import { ApolloProvider, useQuery } from '@apollo/client'
import axios from 'axios'
import Cookies from 'js-cookie'
import { ApolloClientSetup } from '../src/ApolloClientSetup'
import ConfirmationProvider from '../src/ConfirmationProvider'
import { DarkModeContextProvider } from '../src/DarkModeContext'
import ErrorBoundary from '../src/ErrorBoundary'
import Footer from '../src/Footer'
import LoadingPage from '../src/LoadingPage'
import PageTitle from '../src/PageTitle'
import { QueryNavigationProvider } from '../src/QueryNavigationContext'
import ReactQueryProvider from '../src/ReactQueryProvider'
import Snackbar from '../src/Snackbar'
import { StatusEventWidget } from '../src/StatusCheck'
import ThemeProvider from '../src/ThemeProvider'
import TopLevelErrorPage from '../src/TopLevelErrorPage'
import TosPage from '../src/TosPage'
import { StaffContextProvider } from '../src/admin/StaffContext'
import SignUpSetup from '../src/auth/SignUpSetup'
import { currentUserQuery } from '../src/auth/authQueries'
import { useUnauthenticatedRedirect } from '../src/auth/useUnauthenticatedRedirect'
import { AsmContextProvider } from '../src/organization/security/asm/contexts/AsmContext'
import { Page } from '../src/pages/Page'
import { useAppStore } from '../src/stores/appStore/appStore'
import { createApolloClient } from '../src/util'
import { getCsrfToken } from '../src/util/getCsrfToken'

const ClientSideApp = ({ Component, pageProps, client }) => {
  const { data, loading } = useQuery(currentUserQuery, { client })
  const { setOneUiMode } = useAppStore((s) => ({
    setOneUiMode: s.setOneUiMode,
  }))

  useEffect(async () => {
    const csrfToken = await getCsrfToken()
    axios.defaults.headers.common['X-Csrf-Token'] = csrfToken
    axios.defaults.withCredentials = true
    axios.defaults.baseURL = `${process.env.PROTOCOL}://${process.env.API_URL}`
  }, [])

  const { query } = useRouter()
  useEffect(async () => {
    window.consoleApiVersion = {}
    try {
      const res = await fetch(`${process.env.PROTOCOL}://${process.env.API_URL}/_version`)
      window.consoleApiVersion = await res.json()
    } catch (e) {
      console.error('Failed to fetch console-api version', e)
    }

    window.consoleUiVersion = {
      versionTag: process.env.GIT_LATEST_TAG,
      versionSha: process.env.GIT_SHA,
    }
    if (consoleUiVersion.versionTag?.length) {
      console.debug(
        `console-ui version: ${consoleUiVersion.versionTag}-${consoleUiVersion.versionSha}`,
      )
    }
    if (consoleApiVersion.versionTag?.length) {
      console.debug(
        `console-api version: ${consoleApiVersion.versionTag}-${consoleApiVersion.versionSha}`,
      )
    }
  }, [])

  const currentUser = data && (data.currentUser || data.currentMccActor)

  useEffect(() => {
    if (currentUser?.__typename === 'MccActor') {
      if (query.organizationSlug) {
        Cookies.set('OneUiMode._lastVisitedHexId', query.organizationSlug)
      }
      setOneUiMode({
        hexId: query.organizationSlug
          ? query.organizationSlug
          : Cookies.get('OneUiMode._lastVisitedHexId'),
        permissions: currentUser.permissions,
      })
    } else {
      setOneUiMode(null)
    }
  }, [currentUser, query])

  useUnauthenticatedRedirect({ currentUser, loading, bypass: Component.bypassAuth })

  if (loading) {
    return <LoadingPage />
  }

  // prevent app rendering for unauthenticated user before redirect is performed
  if (!currentUser && !Component.bypassAuth) {
    return <LoadingPage />
  }

  if (currentUser && !currentUser.tosAccepted && !(currentUser.__typename === 'MccActor')) {
    Component = TosPage
  }

  // Sign up is incomplete if the user has a temporary password or if they don't have a google or github user id
  if (currentUser?.temporaryPassword && !(currentUser?.githubUser || currentUser?.googleUserId)) {
    Component = SignUpSetup
  }

  let getLayout = Component.getLayout || ((page) => page)

  return (
    <div id="root">
      <Head>
        <title>Edgio</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Head>
      <ApolloProvider client={client}>
        <QueryNavigationProvider>
          <DarkModeContextProvider currentUser={currentUser}>
            <ThemeProvider>
              <Snackbar>
                <ReactQueryProvider>
                  <ApolloClientSetup />
                  <StatusEventWidget />
                  <StaffContextProvider currentUser={currentUser}>
                    <AsmContextProvider>
                      <PageTitle pageName={Component.pageName} />
                      <CssBaseline />
                      <ErrorBoundary message={<TopLevelErrorPage />}>
                        <RegexHelpProvider>
                          {getLayout(
                            <Page>
                              <Component {...pageProps} />
                            </Page>,
                          )}
                        </RegexHelpProvider>
                      </ErrorBoundary>
                      <ConfirmationProvider />
                      {Component.includeFooter && <Footer />}
                    </AsmContextProvider>
                  </StaffContextProvider>
                </ReactQueryProvider>
              </Snackbar>
            </ThemeProvider>
          </DarkModeContextProvider>
        </QueryNavigationProvider>
      </ApolloProvider>
    </div>
  )
}

function App({ Component, pageProps }) {
  const [client, setClient] = useState()

  useEffect(() => {
    const setApolloClient = async () => {
      const apolloClient = await createApolloClient()
      setClient(apolloClient)
    }
    setApolloClient()
  }, [])

  if (client) {
    return <ClientSideApp Component={Component} pageProps={pageProps} client={client} />
  } else {
    return <LoadingPage />
  }
}

export default App
