import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  sidebarContent: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.white,
  },
}))

export const SidebarContent = ({ children }: any) => {
  const { classes } = useStyles()

  return <div className={classes.sidebarContent}>{children}</div>
}
